@media (min-width: 20px) and (max-width: 768px) {
  #HomeBennerSection,
  #AboutBennerSection,
  #PhysioTopBanner,
  #HospitalBanner,
  #professionalBanner,
  #physioknowledgebanner,.secondContainer,#Ourtherapists,#JoinCummunityBanner,#QuestionSection{
    padding-top: 70px !important;
  }
}

@media (min-width: 769px) and (max-width: 991px) {
  #HomeBennerSection,
  #AboutBennerSection,
  #PhysioTopBanner,
  #HospitalBanner,
  #professionalBanner,.secondContainer ,#Ourtherapists,#JoinCummunityBanner,#QuestionSection{
    padding-top: 90px !important;
  }
}

@media (min-width: 992px) and (max-width: 3000px) {
  #HomeBennerSection,
  #AboutBennerSection,
  #PhysioTopBanner,
  #HospitalBanner,
  #professionalBanner ,.secondContainer,#Ourtherapists,#JoinCummunityBanner,#QuestionSection{
    padding-top: 100px !important;
  }
}

/* Faq page query */
@media (min-width: 20px) and (max-width: 768px) {
  #QuestionSection,#BlogTitle,#Detailedtherapists{
    padding-top: 100px !important;
  }
}

@media (min-width: 769px) and (max-width: 991px) {
  #QuestionSection,#BlogTitle,#Detailedtherapists{
    padding-top: 122px !important;
  }
}

@media (min-width: 992px) and (max-width: 3000px) {
  #QuestionSection,#BlogTitle,#Detailedtherapists{
    padding-top: 148px !important;
  }
}

@media (min-width: 1651px){
  #HomeBennerSection,#AboutBennerSection,
  #PhysioTopBanner,
  #HospitalBanner,
  #professionalBanner,
  #physioknowledgebanner,.secondContainer,#Ourtherapists,#JoinCummunityBanner,#QuestionSection{
    padding-top:120px !important;
  }
}

/* Faq page query */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.DetailedS_tab_menu,.container3 {
  height: 82px;
}


